import React from "react";

const link = (link, key) => <a rel="noreferrer" target="_blank" key={key} href={link.url}>{link.name}</a>

const Links = (links) => {
    return (
        <div className="links-box">
            {links.links.map((elem, key) => link(elem, key))}
        </div>
    )
}

export default Links