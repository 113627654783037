import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Links from "./links"

const BlogPostTemplate = ({data, location}) => {
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <article
                className="blog-post"
                itemScope
                itemType="http://schema.org/Article"
            >
                <header className="post-header">
                    <div className="img-box inner-container">
                        <img alt="pattern" src={`${post.frontmatter.pattern.childImageSharp.fluid.src}`}/>
                        <img alt="profile picture" src={post.frontmatter.profile_picture.childImageSharp.fluid.src}/>
                    </div>
                </header>
                <section className="article-body">
                    <div className="inner-container">
                        <div className="title-box">
                            <h1 className="text-center">{post.frontmatter.title}</h1>
                            <p className="text-center">{post.frontmatter.position}</p>
                        </div>
                        <Links links={post.frontmatter.links} />
                    </div>
                </section>
            </article>
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        position
        pattern {
            childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        profile_picture {
            childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        links {
            name
            url
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
